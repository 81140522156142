<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THÔNG TIN ĐĂNG KÝ</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false">
            <div class="frame-dang-ky pa-3">
                <DxValidationGroup ref="formValidation">
                    <div class="row font-12">Họ tên (*)</div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                v-model="HoTen"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                :tabIndex="1"
                            >
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Họ tên không được để trống!"
                                    />
                                    <DxStringLengthRule
                                        :max="128"
                                        message="Họ tên không được vượt quá 128 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>

                    <div class="row font-12">Số điện thoại (*)</div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                v-model="SDT"
                                validationMessageMode="always"
                                mode="tel"
                                :tabIndex="2"
                                ref="refTab1"
                                styling-mode="underlined"
                                valueChangeEvent="keyup"
                            >
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Số điện thoại không được để trống!"
                                    />
                                    <DxStringLengthRule
                                        :max="13"
                                        message="Số điện thoại không được vượt quá 13 ký tự!"
                                    />
                                    <DxNumericRule
                                        message="Số điện thoại phải là số!"
                                    />
                                    <DxRangeRule
                                        :min="0"
                                        message="Số điện thoại phải là số!"
                                    />
                                    <DxPatternRule
                                        :pattern="phonePattern"
                                        message="Vui lòng nhập đúng định dạng số điện thoại!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>

                    <div class="row font-12">Ngày sinh (*)</div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                v-model="NgaySinh"
                                validationMessageMode="always"
                                displayFormat="dd/MM/yyyy"
                                dropDownButtonTemplate="customIcon"
                                type="date"
                                :tabIndex="3"
                                :useMaskBehavior="true"
                                styling-mode="underlined"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Ngày sinh không được để trống!"
                                    />
                                    <DxRangeRule
                                        :max="new Date()"
                                        message="Ngày sinh không được lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>

                    <div class="row font-12">Số GPLX (*)</div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                v-model="SoGPLX"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                :tabIndex="4"
                            >
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Số GPLX không được để trống!"
                                    />
                                    <DxStringLengthRule
                                        :max="128"
                                        message="Số GPLX không được vượt quá 128 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>

                    <div class="row font-12">Hạng bằng (*)</div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                v-model="HangBang"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                :tabIndex="5"
                            >
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạng bằng không được để trống!"
                                    />
                                    <DxStringLengthRule
                                        :max="128"
                                        message="Hạng bằng không được vượt quá 128 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>

                    <div class="frame-button justify-space-between row">
                        <ion-button
                            color="primary"
                            fill="clear"
                            @click="$router.go(-1)"
                        >
                            Hủy
                        </ion-button>

                        <ion-button
                            color="primary"
                            fill="solid"
                            expand="block"
                            @click="DangKy"
                        >
                            Đăng ký
                        </ion-button>
                    </div>
                </DxValidationGroup>
            </div>

            <DxPopup
                v-model:visible="dialogThongBao"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-title="false"
                width="90%"
                height="auto"
            >
                <Notification :Params="ParamsError" @click="hiddenDialog" />
            </DxPopup>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
} from "@ionic/vue";
import {
    DxTextBox,
    DxButton,
    DxDateBox,
    DxValidator,
    DxValidationGroup,
    DxPopup,
} from "devextreme-vue";
import {
    DxNumericRule,
    DxPatternRule,
    DxRangeRule,
    DxRequiredRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import Notification from "@sonphat/common-v1/components/Notification.vue";
export default {
    IsFreePage: true,
    components: {
        IonPage,
        IonContent,
        IonToolbar,
        IonHeader,
        DxTextBox,
        DxButton,
        DxRequiredRule,
        DxValidator,
        DxDateBox,
        DxStringLengthRule,
        DxNumericRule,
        DxRangeRule,
        DxPatternRule,
        DxDateBox,
        IonButton,
        DxValidationGroup,
        Notification,
        DxPopup,
    },
    data() {
        return {
            HoTen: null,
            SDT: null,
            NgaySinh: null,
            SoGPLX: null,
            HangBang: null,
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            dialogThongBao: false,
            ParamsError: {},
        };
    },
    computed: {},
    methods: {
        async DangKy() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                let toast = {
                    type: "error",
                    visible: true,
                };
                try {
                    this.$startLoading;
                    let result = await this.$Core.Api.TaiKhoan(
                        this.$t("urlTaiKhoan.DangKyTaiKhoan")
                    ).Post({
                        HoTen: this.HoTen,
                        SoDienThoai: this.SDT,
                        NgaySinh: this.NgaySinh,
                        SoGPLX: this.SoGPLX,
                        HangBang: this.HangBang,
                    });
                    if (result.Data.status) {
                        this.ParamsError.state = "Success";
                        this.ParamsError.message_title =
                            "Thực hiện gửi thông tin đăng ký mở tài khoản thành công!";
                        this.ParamsError.message =
                            "Chúng tôi sẽ thực hiện xác minh thông tin đăng ký và phản hồi kết quả qua điện thoại trong tối đa 5 ngày làm việc. Xin cảm ơn!";
                        this.dialogThongBao = true;
                    } else {
                        toast.message =
                            "Đăng ký tài khoản thất bại! " +
                            result.Data.message;
                        this.emitter.emit("onToast", toast);
                    }
                    this.$stopLoading;
                } catch (error) {
                    this.$stopLoading;
                    if (error.StatusMessage) {
                        toast.message = error.StatusMessage;
                    } else {
                        toast.message = error;
                    }
                    this.emitter.emit("onToast", toast);
                }
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
        hiddenDialog() {
            this.dialogThongBao = false;
            this.clearData();
            this.$router.push("/dang-nhap");
        },
        clearData() {
            this.HoTen = null;
            this.SDT = null;
            this.NgaySinh = null;
            this.SoGPLX = null;
            this.HangBang = null;
            this.$refs.formValidation.instance.reset();
        },
    },
};
</script>
<style scoped>
.frame-dang-ky {
    position: relative;
    height: calc(100vh - 36px);
}
.frame-button {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 8px;
    border-top: 1px solid #dadce0;
}
</style>